import 'vite/modulepreload-polyfill'

window.addEventListener('DOMContentLoaded', function() {
  const shareElements = document.getElementsByClassName('share')
  for (let i = 0; i < shareElements.length; i++) {
    let shareElement = shareElements[i]
    shareElement.addEventListener('click', shareClick, false)
  }
})

window.shareClick = function(e) {
  e.preventDefault()
  const text = e.currentTarget.getAttribute('data-text')
  shareText(text)
}

window.shareText = function(text) {
  if (typeof (Android) != 'undefined') {
    Android.shareText(text)
  }
  if (window.webkit) {
    if (window.webkit.messageHandlers.shareText) {
      window.webkit.messageHandlers.shareText.postMessage(text)
    } else {
      // alte Variante bis Version 5429
      window.location = 'kicktipp://share/?text=' + encodeURIComponent(text)
    }
  }
}
