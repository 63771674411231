window.addEventListener('orientationchange', function() {
  $('#adtag_banner').hide()
  $('#adtag_sky').hide()
})
// window.open verbieten, um wilde Werbebanner im Zaum zu halten
// window.open = function(open) {
//   return function(url, nam, features) {
//     // set name if missing here
//     console.log('window.open is not allowed')
//   }
// }(window.open)
