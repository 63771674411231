import 'vite/modulepreload-polyfill'

window.dismiss = function(id) {
  const el = document.getElementById(id)
  if (el !== null) {
    el.style.display = 'none'
  }
}
window.registerPush = function() {
  if (window.webkit) {
    window.webkit.messageHandlers.registerPush.postMessage('')
  } else {
    Android.registerPush()
  }
}

window.openSettings = function() {
  if (window.webkit) {
    window.webkit.messageHandlers.openSettings.postMessage('')
  } else {
    Android.showAppSettings()
  }
}
